@import 'assets/scss/colors.scss';

@import 'assets/scss/mixins/typography.scss';
@import 'assets/scss/mixins/button.scss';
@import 'assets/scss/mixins/labels.scss';
@import 'assets/scss/mixins/toast.scss';
@import 'assets/scss/mixins/tooltip.scss';
@import 'assets/scss/mixins/input.scss';
@import 'assets/scss/mixins/checkbox.scss';
@import 'assets/scss/mixins/dropdown.scss';
@import 'assets/scss/mixins/cards.scss';
@import 'assets/scss/mixins/table.scss';
@import 'assets/scss/mixins/utils.scss';

@mixin modals {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 27.5rem;
  padding: 2rem 3.5rem;
  text-align: center;
  background-color: $white;
}

@mixin placeHolderButtonWithImage {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
}

@mixin profileImagePlaceHolderRichContent {
  img,
  svg {
    width: 5.25rem;
    height: 5.25rem;
    border-radius: 100%;
    background-color: $emerald9;
    cursor: pointer;
  }
  button {
    display: none;
  }
  .mainImage {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 100%;
    background-color: $emerald8;
    cursor: pointer;
    position: relative;
    img,
    svg {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin defaultPlaceholderImage {
  .mainImage {
    width: 22.5rem;
    height: 15rem;
    img {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin richContentTestimonialCommon {
  @include small($oxfordBlue);
  display: grid;
}

@mixin round-card($shadow-color: $manatte8, $inner-card: true) {
  box-shadow: 0px 0px 4px 4px $shadow-color;
  border-radius: 1rem;
  padding: 1em;

  @if $inner-card {
    > * {
      height: 100%;
      border-radius: 0.875rem;
    }
  }
}

@mixin loading {
  background: $manatte;
  background: linear-gradient(
    305deg,
    $manatte,
    $manatte5,
    $manatte
  );
  background-size: 400% 400%;
  animation: gradient-animation 1s linear infinite;

  @keyframes gradient-animation {
    0% {
      background-position: 133% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

@import '~bootstrap/scss/bootstrap';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

// Colors
:root {
  --white: #{$white};
  --black: #{$black};

  // PRIMARY COLORS
  --emerald: #{$emerald};
  --emerald-shade-1: #{$emerald1};
  --emerald-shade-2: #{$emerald2};
  --emerald-shade-3: #{$emerald3};
  --emerald-shade-4: #{$emerald4};
  --emerald-shade-5: #{$emerald5};
  --emerald-shade-6: #{$emerald6};
  --emerald-shade-7: #{$emerald7};
  --emerald-shade-8: #{$emerald8};
  --emerald-shade-9: #{$emerald9};

  --oxford-blue: #{$oxfordBlue};
  --oxford-blue-shade-1: #{$oxfordBlue1};
  --oxford-blue-shade-2: #{$oxfordBlue2};
  --oxford-blue-shade-3: #{$oxfordBlue3};
  --oxford-blue-shade-4: #{$oxfordBlue4};
  --oxford-blue-shade-5: #{$oxfordBlue5};
  --oxford-blue-shade-6: #{$oxfordBlue6};
  --oxford-blue-shade-7: #{$oxfordBlue7};
  --oxford-blue-shade-8: #{$oxfordBlue8};
  --oxford-blue-shade-9: #{$oxfordBlue9};

  --manatte: #{$manatte};
  --manatte-shade-1: #{$manatte1};
  --manatte-shade-2: #{$manatte2};
  --manatte-shade-3: #{$manatte3};
  --manatte-shade-4: #{$manatte4};
  --manatte-shade-5: #{$manatte5};
  --manatte-shade-6: #{$manatte6};
  --manatte-shade-7: #{$manatte7};
  --manatte-shade-8: #{$manatte8};
  --manatte-shade-9: #{$manatte9};

  // ACCENT COLORS
  --bleu-de-france: #{$bleuDeFrance};
  --bleu-de-france-shade-1: #{$bleuDeFrance1};
  --bleu-de-france-shade-2: #{$bleuDeFrance2};
  --bleu-de-france-shade-3: #{$bleuDeFrance3};
  --bleu-de-france-shade-4: #{$bleuDeFrance4};
  --bleu-de-france-shade-5: #{$bleuDeFrance5};
  --bleu-de-france-shade-6: #{$bleuDeFrance6};
  --bleu-de-france-shade-7: #{$bleuDeFrance7};
  --bleu-de-france-shade-8: #{$bleuDeFrance8};
  --bleu-de-france-shade-9: #{$bleuDeFrance9};

  --capri: #{$capri};
  --capri-shade-1: #{$capri1};
  --capri-shade-2: #{$capri2};
  --capri-shade-3: #{$capri3};
  --capri-shade-4: #{$capri4};
  --capri-shade-5: #{$capri5};
  --capri-shade-6: #{$capri6};
  --capri-shade-7: #{$capri7};
  --capri-shade-8: #{$capri8};
  --capri-shade-9: #{$capri9};
  --capri-shade-10: #{$capri10};

  --turquoise: #{$turquoise};
  --turquoise-shade-1: #{$turquoise1};
  --turquoise-shade-2: #{$turquoise2};
  --turquoise-shade-3: #{$turquoise3};
  --turquoise-shade-4: #{$turquoise4};
  --turquoise-shade-5: #{$turquoise5};
  --turquoise-shade-6: #{$turquoise6};
  --turquoise-shade-7: #{$turquoise7};
  --turquoise-shade-8: #{$turquoise8};
  --turquoise-shade-9: #{$turquoise9};

  --sizzling-red: #{$sizzlingRed};
  --sizzling-red-shade-1: #{$sizzlingRed1};
  --sizzling-red-shade-2: #{$sizzlingRed2};
  --sizzling-red-shade-3: #{$sizzlingRed3};
  --sizzling-red-shade-4: #{$sizzlingRed4};
  --sizzling-red-shade-5: #{$sizzlingRed5};
  --sizzling-red-shade-6: #{$sizzlingRed6};
  --sizzling-red-shade-7: #{$sizzlingRed7};
  --sizzling-red-shade-8: #{$sizzlingRed8};
  --sizzling-red-shade-9: #{$sizzlingRed9};

  --light-salmon: #{$lightSalmon};
  --light-salmon-shade-1: #{$lightSalmon1};
  --light-salmon-shade-2: #{$lightSalmon2};
  --light-salmon-shade-3: #{$lightSalmon3};
  --light-salmon-shade-4: #{$lightSalmon4};
  --light-salmon-shade-5: #{$lightSalmon5};
  --light-salmon-shade-6: #{$lightSalmon6};
  --light-salmon-shade-7: #{$lightSalmon7};
  --light-salmon-shade-8: #{$lightSalmon8};
  --light-salmon-shade-9: #{$lightSalmon9};

  --bright-yellow-crayola: #{$brightYellowCrayola};
  --bright-yellow-crayola-shade-1: #{$brightYellowCrayola1};
  --bright-yellow-crayola-shade-2: #{$brightYellowCrayola2};
  --bright-yellow-crayola-shade-3: #{$brightYellowCrayola3};
  --bright-yellow-crayola-shade-4: #{$brightYellowCrayola4};
  --bright-yellow-crayola-shade-5: #{$brightYellowCrayola5};
  --bright-yellow-crayola-shade-6: #{$brightYellowCrayola6};
  --bright-yellow-crayola-shade-7: #{$brightYellowCrayola7};
  --bright-yellow-crayola-shade-8: #{$brightYellowCrayola8};
  --bright-yellow-crayola-shade-9: #{$brightYellowCrayola9};
}

// Variables
:root {
  // Layout
  --main-page-navbar: 4.25rem;
  --large-button-height: 3rem;
  --medium-button-height: 2rem;
  --small-button-height: 1.25rem;
  --secondary-button-height: 1.375rem;
  --sub-main-content-header: 4.375rem;

  // GRADIENTS
  --primary-gradient: linear-gradient(
    90.15deg,
    #{$emerald} 0.13%,
    #{$capri} 98.57%
  );

  // BOX SHADOWS
  --primary-box-shadow: 0px 4px 12px #{$oxfordBlue9};
}

// Scrollbar
:root {
  scrollbar-color: #576474 !important;
  scrollbar-width: 4px !important;

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #576474;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #2d3d51;
  }

  // Remove scrollbar from number input
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }
}

// Base overwrites

p {
  margin: 0;
}
:root {
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: subpixel-antialiased !important;

  body {
    color: $oxfordBlue;
    scroll-behavior: smooth;
  }

  a {
    text-decoration: none !important;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  td {
    vertical-align: middle !important;
  }

  hr {
    margin: 0;
    border-top: 1px solid $oxfordBlue6;
    margin: 0px -15px 0px -15px;
  }

  // This disables an iframe blocking interaction added by some unexplained logic
  iframe[style*='position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; border: none; z-index: 2147483647;'] {
    display: none !important;
  }
}

// Custom styles
:root {
  // UTILITIES
  $sides: (
    'l': 'left',
    'r': 'right',
    't': 'top',
    'b': 'bottom',
  );

  @function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
      @return str-slice($string, 1, $index - 1) + $replace +
        str-replace(
          str-slice($string, $index + str-length($search)),
          $search,
          $replace
        );
    }

    @return $string;
  }
  @for $i from 0 to 22 {
    @each $short, $full in $sides {
      .p#{$short}-#{str-replace(calc($i / 2) + '' , '.', '_')} {
        padding-#{$full}: #{calc($i / 2)}rem !important;
      }

      .m#{$short}-#{str-replace(calc($i / 2) + '', '.', '_')} {
        margin-#{$full}: #{calc($i / 2)}rem !important;
      }
    }

    .p-#{str-replace(calc($i / 2) + '', '.', '_')} {
      padding: #{calc($i / 2)}rem;
    }

    .m-#{str-replace(calc($i / 2) + '', '.', '_')} {
      margin: #{calc($i / 2)}rem;
    }
  }

  .reactEasyCrop_CropArea {
    box-shadow: 0 0 0 9999em rgba($color: $white, $alpha: 0.5) !important;
  }

  .disable-field {
    pointer-events: none;
    opacity: 0.5;
  }

  .flex-1 {
    flex: 1;
  }

  .truncate-3-line {
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }
  .truncate-2-line {
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }

  @media (min-width: 1440px) {
    .container {
      max-width: 1320px;
    }
  }

  .text-align-center {
    text-align: center;
  }

  .text-emerald {
    color: $emerald;
  }

  .text-oxford-blue {
    color: $oxfordBlue;
  }

  .text-sizzling-red {
    color: $sizzlingRed;
  }

  .bg-emerald {
    background-color: $emerald;
  }

  .error-state-graph-placeholder {
    height: 460px;
  }

  .text-break {
    word-break: break-word;
  }

  .d-flex {
    display: flex;
  }

  .flex-column {
    flex-direction: column;
  }

  .justify-content-start {
    justify-content: flex-start;
  }

  .justify-content-center {
    justify-content: center;
  }

  .justify-content-between {
    justify-content: space-between;
  }

  .justify-content-end {
    justify-content: flex-end;
  }

  .align-items-center {
    align-items: center;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .w-100 {
    width: 100%;
  }

  .h-100 {
    height: 100%;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.4;
    &:hover {
      box-shadow: none;
    }
  }

  .read-only {
    pointer-events: none;
    &:hover {
      box-shadow: none;
    }
  }

  .text-uppercase {
    text-transform: uppercase;
  }

  .text-capitalize {
    text-transform: capitalize;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .badge-sizzling-red {
    height: 1.125rem;
    width: 1.125rem;
    background: $sizzlingRed;
    @include small($white);
    font-weight: 500;
    border-radius: 50%;
    display: grid;
    place-content: center;
  }

  .ml-0_5 {
    margin-left: 0.5rem !important;
  }
  .mt-0_5 {
    margin-top: 0.5rem !important;
  }
}
