@import '../../../../assets/scss/colors.scss';

@import '../../../../assets/scss/mixins/typography.scss';
@import '../../../../assets/scss/mixins/button.scss';
@import '../../../../assets/scss/mixins/labels.scss';
@import '../../../../assets/scss/mixins/toast.scss';
@import '../../../../assets/scss/mixins/tooltip.scss';
@import '../../../../assets/scss/mixins/input.scss';
@import '../../../../assets/scss/mixins/checkbox.scss';
@import '../../../../assets/scss/mixins/dropdown.scss';
@import '../../../../assets/scss/mixins/cards.scss';
@import '../../../../assets/scss/mixins/table.scss';
@import '../../../../assets/scss/mixins/utils.scss';

@mixin modals {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 27.5rem;
  padding: 2rem 3.5rem;
  text-align: center;
  background-color: $white;
}

@mixin placeHolderButtonWithImage {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
}

@mixin profileImagePlaceHolderRichContent {
  img,
  svg {
    width: 5.25rem;
    height: 5.25rem;
    border-radius: 100%;
    background-color: $emerald9;
    cursor: pointer;
  }
  button {
    display: none;
  }
  .mainImage {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 100%;
    background-color: $emerald8;
    cursor: pointer;
    position: relative;
    img,
    svg {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin defaultPlaceholderImage {
  .mainImage {
    width: 22.5rem;
    height: 15rem;
    img {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin richContentTestimonialCommon {
  @include small($oxfordBlue);
  display: grid;
}

@mixin round-card($shadow-color: $manatte8, $inner-card: true) {
  box-shadow: 0px 0px 4px 4px $shadow-color;
  border-radius: 1rem;
  padding: 1em;

  @if $inner-card {
    > * {
      height: 100%;
      border-radius: 0.875rem;
    }
  }
}

@mixin loading {
  background: $manatte;
  background: linear-gradient(
    305deg,
    $manatte,
    $manatte5,
    $manatte
  );
  background-size: 400% 400%;
  animation: gradient-animation 1s linear infinite;

  @keyframes gradient-animation {
    0% {
      background-position: 133% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

.RichContentImageUploader {
  display: grid;
  height: max-content;
  grid-row-gap: 0.875rem;

  &__textDetails {
    display: grid;
    grid-template-columns: 21.875rem 1fr;
    grid-column-gap: 2.1875rem;
    padding: 0 1.5rem;

    &--container {
      display: grid;
      grid-row-gap: 0.625rem;
      grid-template-rows: max-content 1fr max-content;
      & > p {
        @include h5($emerald);
        margin-bottom: 1.25rem;
        margin-top: 0.625rem;
        cursor: pointer;
      }
      .heading {
        display: grid;
        grid-template-rows: max-content max-content;
      }
      .description {
        display: grid;
        grid-template-rows: max-content 1fr;
        textarea,
        .ql-editor {
          width: 100%;
          height: 8rem;
        }
      }
    }

    .image-container {
      width: 22.5rem;
      height: 15rem;
      background-color: $emerald9;
      display: grid;
      position: relative;
      place-content: center;
      justify-items: center;
      @include defaultPlaceholderImage();
    }

    &.reverse {
      grid-template-columns: 1fr 21.875rem;
      .image-container {
        grid-column: 2/3;
        grid-row: 1/2;
      }
      .RichContentImageUploader__textDetails--container {
        grid-column: 1/2;
        grid-row: 1/2;
      }
    }
  }

  &__modal {
    width: 31.25vw;
    padding: 2.5rem 4.5rem;
    .heading {
      text-align: center;
      @include h2($oxfordBlue1);
      padding-bottom: 2.5rem;
    }

    .ctaInput {
      padding: 1rem 0;
      textarea {
        max-height: 2.5rem;
        padding: 0.5rem;
      }
    }
    .addButton {
      padding-top: 1.25rem;
      text-align: right;
    }
  }
  &__ctaUploadContainer {
    .ctaContainer {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: max-content;
      align-items: center;
      grid-column-gap: 0.625rem;
    }

    .buttonDetails {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: max-content;
      grid-column-gap: 2.5rem;
    }

    .ctaHeading {
      @include h5($oxfordBlue1);
    }

    .label {
      @include small($oxfordBlue1);
      @include text-ellipses();
      user-select: none;
    }

    a.label {
      user-select: initial;
      cursor: pointer;
      color: var(--primary);
      text-decoration: underline !important;
    }

    .actionIcon {
      & > svg {
        width: 0.75rem;
        height: 0.75rem;
        cursor: pointer;
        margin: 0 0.125rem;
        & * {
          fill: $oxfordBlue5;
        }
      }
    }

    .value {
      @include small(($manatte));
      @include text-ellipses();
      user-select: none;
    }
  }
}
