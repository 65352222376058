@import '../../../assets/scss/colors.scss';

@import '../../../assets/scss/mixins/typography.scss';
@import '../../../assets/scss/mixins/button.scss';
@import '../../../assets/scss/mixins/labels.scss';
@import '../../../assets/scss/mixins/toast.scss';
@import '../../../assets/scss/mixins/tooltip.scss';
@import '../../../assets/scss/mixins/input.scss';
@import '../../../assets/scss/mixins/checkbox.scss';
@import '../../../assets/scss/mixins/dropdown.scss';
@import '../../../assets/scss/mixins/cards.scss';
@import '../../../assets/scss/mixins/table.scss';
@import '../../../assets/scss/mixins/utils.scss';

@mixin modals {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 27.5rem;
  padding: 2rem 3.5rem;
  text-align: center;
  background-color: $white;
}

@mixin placeHolderButtonWithImage {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
}

@mixin profileImagePlaceHolderRichContent {
  img,
  svg {
    width: 5.25rem;
    height: 5.25rem;
    border-radius: 100%;
    background-color: $emerald9;
    cursor: pointer;
  }
  button {
    display: none;
  }
  .mainImage {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 100%;
    background-color: $emerald8;
    cursor: pointer;
    position: relative;
    img,
    svg {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin defaultPlaceholderImage {
  .mainImage {
    width: 22.5rem;
    height: 15rem;
    img {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin richContentTestimonialCommon {
  @include small($oxfordBlue);
  display: grid;
}

@mixin round-card($shadow-color: $manatte8, $inner-card: true) {
  box-shadow: 0px 0px 4px 4px $shadow-color;
  border-radius: 1rem;
  padding: 1em;

  @if $inner-card {
    > * {
      height: 100%;
      border-radius: 0.875rem;
    }
  }
}

@mixin loading {
  background: $manatte;
  background: linear-gradient(
    305deg,
    $manatte,
    $manatte5,
    $manatte
  );
  background-size: 400% 400%;
  animation: gradient-animation 1s linear infinite;

  @keyframes gradient-animation {
    0% {
      background-position: 133% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

$root: '.SideBar';

#{$root} {
  &-logo {
    display: grid;
    width: 100%;
    height: 3.125rem;
    object-fit: cover;
    align-items: center;
    background-color: $emerald;
    padding: 0 0.4375rem;
    img {
      height: 2.25rem;
    }
  }
  &-toggleIcon:hover {
    padding: 0 0.2rem;
    background-color: $oxfordBlue4;
    border-radius: 2px;
  }

  &-itemsContainer,
  .SidebarLoading {
    display: grid;
    grid-row-gap: 0.5rem;
    grid-template-rows: 1fr max-content;
    overflow-y: auto;
    overflow-y: overlay;
    overflow-x: hidden;
    & > div {
      display: grid;
      grid-auto-rows: max-content;
      margin-top: 2.5rem;
    }
  }

  &-bellIconContainer {
    position: relative;
    &__notificationCount {
      position: absolute;
      top: -0.375rem;
      right: 0.375rem;
    }
  }

  &-startGuide {
    display: grid;
    align-items: center;
    margin: auto;
    height: 2rem;
    width: 2rem;
    border: 0.0625rem solid;
    border-radius: 1rem;
    @include small;
  }
}

.SidebarItemLoading {
  display: grid;
  grid-template-columns: 1.5rem 9.375rem;
  grid-column-gap: 0.8125rem;
  padding: 0.8125rem;

  &-icon,
  &-label {
    height: 1.5rem;
    background-image: linear-gradient(to left, #425163, #425153);
  }
  &-icon {
    width: 1.625rem;
    margin-left: 0.2rem;
  }
  &-label {
    width: 9.375rem;
    margin-left: 0.5rem;
  }
}
