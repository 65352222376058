@mixin commonType($color: null) {
  font-stretch: normal;
  font-style: normal;
  color: $color;
}

//font-size 14px
@mixin button1($color: null) {
  @include commonType($color);
  font-size: 0.875rem;
  font-weight: 500;
}

//font-size 10px
@mixin button2($color: null) {
  @include commonType($color);
  font-size: 0.625rem;
  font-weight: 500;
}

@mixin text-ellipses {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@mixin text-clamp($lines: 1) {
  display: -moz-box;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// <---------- Title ----------->
// font-size: 72px;
@mixin t1($color: null) {
  @include commonType($color);
  font-size: 4.5rem;
  font-weight: 700;
  line-height: 5.625rem;
}
// font-size: 60px;
@mixin t2($color: null) {
  @include commonType($color);
  font-size: 3.75rem;
  font-weight: 700;
  line-height: 5rem;
}
// font-size: 48px;
@mixin t3($color: null) {
  @include commonType($color);
  font-size: 3rem;
  font-weight: 700;
  line-height: 4.375rem;
}
// font-size: 36px;
@mixin t4($color: null) {
  @include commonType($color);
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 3rem;
}

// <---------- Sub-title ----------->
// font-size: 48px;
@mixin stl1($color: null) {
  @include commonType($color);
  font-size: 3rem;
  font-weight: 500;
  line-height: 4.375rem;
}
// font-size: 36px;
@mixin stl2($color: null) {
  @include commonType($color);
  font-size: 2.25rem;
  font-weight: 500;
  line-height: 3rem;
}

// <---------- Headings ----------->
// font-size: 24px;
@mixin h1($color: null) {
  @include commonType($color);
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2.125rem;
}
// font-size: 16px;
@mixin h2($color: null) {
  @include commonType($color);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
}
// font-size: 14px;
@mixin h3($color: null) {
  @include commonType($color);
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.125rem;
}
// font-size: 12px;
@mixin h4($color: null) {
  @include commonType($color);
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1rem;
}
// font-size: 10px;
@mixin h5($color: null) {
  @include commonType($color);
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 0.875rem;
}

// <---------- Sub-headings ----------->
// font-size: 24px;
@mixin sh1($color: null) {
  @include commonType($color);
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.125rem;
}
// font-size: 16px;
@mixin sh2($color: null) {
  @include commonType($color);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
}
// font-size: 14px;
@mixin sh3($color: null) {
  @include commonType($color);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.125rem;
}
// font-size: 12px;
@mixin sh4($color: null) {
  @include commonType($color);
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
}

// <---------- Sub Text ----------->
// font-size: 18px;
@mixin st1($color: null) {
  @include commonType($color);
  font-size: 1.125rem;
  line-height: 1.75rem;
}
// font-size: 14px
@mixin st2($color: null) {
  @include commonType($color);
  font-size: 0.875rem;
  line-height: 1.125rem;
}

// font-size: 16px;
@mixin body($color: null) {
  @include commonType($color);
  font-size: 1rem;
  line-height: 1.5rem;
}

// font-size: 12px;
@mixin small($color: null) {
  @include commonType($color);
  font-size: 0.75rem;
  line-height: 1rem;
}

// <---------- Italic Bold ----------->
// font-size: 16px;
@mixin Ib1($color: null) {
  @include commonType($color);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  font-style: italic;
}
// font-size: 14px;
@mixin Ib2($color: null) {
  @include commonType($color);
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.125rem;
  font-style: italic;
}
// font-size: 12px;
@mixin Ib3($color: null) {
  @include commonType($color);
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.125rem;
  font-style: italic;
}

// <---------- Italic ----------->
// font-size: 16px;
@mixin I1($color: null) {
  @include commonType($color);
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: italic;
}
// font-size: 14px;
@mixin I2($color: null) {
  @include commonType($color);
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-style: italic;
}
// font-size: 12px;
@mixin I3($color: null) {
  @include commonType($color);
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-style: italic;
}
