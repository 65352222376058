@import '../../assets/scss/colors.scss';

@import '../../assets/scss/mixins/typography.scss';
@import '../../assets/scss/mixins/button.scss';
@import '../../assets/scss/mixins/labels.scss';
@import '../../assets/scss/mixins/toast.scss';
@import '../../assets/scss/mixins/tooltip.scss';
@import '../../assets/scss/mixins/input.scss';
@import '../../assets/scss/mixins/checkbox.scss';
@import '../../assets/scss/mixins/dropdown.scss';
@import '../../assets/scss/mixins/cards.scss';
@import '../../assets/scss/mixins/table.scss';
@import '../../assets/scss/mixins/utils.scss';

@mixin modals {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 27.5rem;
  padding: 2rem 3.5rem;
  text-align: center;
  background-color: $white;
}

@mixin placeHolderButtonWithImage {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
}

@mixin profileImagePlaceHolderRichContent {
  img,
  svg {
    width: 5.25rem;
    height: 5.25rem;
    border-radius: 100%;
    background-color: $emerald9;
    cursor: pointer;
  }
  button {
    display: none;
  }
  .mainImage {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 100%;
    background-color: $emerald8;
    cursor: pointer;
    position: relative;
    img,
    svg {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin defaultPlaceholderImage {
  .mainImage {
    width: 22.5rem;
    height: 15rem;
    img {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin richContentTestimonialCommon {
  @include small($oxfordBlue);
  display: grid;
}

@mixin round-card($shadow-color: $manatte8, $inner-card: true) {
  box-shadow: 0px 0px 4px 4px $shadow-color;
  border-radius: 1rem;
  padding: 1em;

  @if $inner-card {
    > * {
      height: 100%;
      border-radius: 0.875rem;
    }
  }
}

@mixin loading {
  background: $manatte;
  background: linear-gradient(
    305deg,
    $manatte,
    $manatte5,
    $manatte
  );
  background-size: 400% 400%;
  animation: gradient-animation 1s linear infinite;

  @keyframes gradient-animation {
    0% {
      background-position: 133% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

$root: '.Card';

#{$root} {
  transition: 0.2s ease;
  box-shadow: 0px 0px 0.416rem 0px #141e382e;
  border-radius: 0.625rem;
  background: $white;
  &.show-shadow {
    &:hover {
      box-shadow: 0 1.25rem 1.25rem 0 rgba(45, 61, 81, 0.25);
      cursor: pointer;
    }
  }
  &.show-icons {
    position: relative;
    &:hover {
      #{$root} {
        &-actions {
          position: absolute;
          display: flex;
          top: -1.25rem;
          right: 0.0625rem;
          height: auto;
          &__action {
            width: 1.25rem;
            height: 1.25rem;
            img {
              vertical-align: top;
            }
          }
        }
      }
    }
  }
  &.show-icons {
    #{$root} {
      &-actions {
        display: none;
      }
    }
  }
  &:focus-within,
  &:active {
    &.show-active {
      border: 1px solid $oxfordBlue;
      position: relative;
      #{$root} {
        &-actions {
          display: none;
        }
        &-activeIcon {
          position: absolute;
          display: flex;
          top: -1.3125rem;
          right: -0.0625rem;
          width: 1.25rem;
          height: 1.25rem;
          background-color: $oxfordBlue;
          &__container {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
  &.show-active {
    #{$root} {
      &-activeIcon {
        display: none;
      }
    }
  }
  &.hide-shadow {
    box-shadow: none;
  }
}
