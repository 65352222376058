@import '../../assets/scss/colors.scss';

@import '../../assets/scss/mixins/typography.scss';
@import '../../assets/scss/mixins/button.scss';
@import '../../assets/scss/mixins/labels.scss';
@import '../../assets/scss/mixins/toast.scss';
@import '../../assets/scss/mixins/tooltip.scss';
@import '../../assets/scss/mixins/input.scss';
@import '../../assets/scss/mixins/checkbox.scss';
@import '../../assets/scss/mixins/dropdown.scss';
@import '../../assets/scss/mixins/cards.scss';
@import '../../assets/scss/mixins/table.scss';
@import '../../assets/scss/mixins/utils.scss';

@mixin modals {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 27.5rem;
  padding: 2rem 3.5rem;
  text-align: center;
  background-color: $white;
}

@mixin placeHolderButtonWithImage {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
}

@mixin profileImagePlaceHolderRichContent {
  img,
  svg {
    width: 5.25rem;
    height: 5.25rem;
    border-radius: 100%;
    background-color: $emerald9;
    cursor: pointer;
  }
  button {
    display: none;
  }
  .mainImage {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 100%;
    background-color: $emerald8;
    cursor: pointer;
    position: relative;
    img,
    svg {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin defaultPlaceholderImage {
  .mainImage {
    width: 22.5rem;
    height: 15rem;
    img {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin richContentTestimonialCommon {
  @include small($oxfordBlue);
  display: grid;
}

@mixin round-card($shadow-color: $manatte8, $inner-card: true) {
  box-shadow: 0px 0px 4px 4px $shadow-color;
  border-radius: 1rem;
  padding: 1em;

  @if $inner-card {
    > * {
      height: 100%;
      border-radius: 0.875rem;
    }
  }
}

@mixin loading {
  background: $manatte;
  background: linear-gradient(
    305deg,
    $manatte,
    $manatte5,
    $manatte
  );
  background-size: 400% 400%;
  animation: gradient-animation 1s linear infinite;

  @keyframes gradient-animation {
    0% {
      background-position: 133% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

.SearchBar {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-column-gap: 1rem;
  align-items: center;

  &-withInput {
    position: relative;
  }
  &-searchInput {
    display: grid;
    grid-template-columns: 1rem 1fr 1rem;
    grid-column-gap: 1rem;
    align-items: center;
    padding: 0.4rem;
    border: 0.08rem solid $oxfordBlue;
    border-radius: 8px;

    &:hover {
      border: 0.125rem solid $emerald;
    }
    &:focus-within {
      border: 0.125rem solid $emerald;
      svg * {
        fill: $oxfordBlue1;
      }
    }

    input {
      border: none;
      background: none;

      &::placeholder,
      &:read-only {
        color: $oxfordBlue7;
      }
      &:focus {
        outline: none;
      }
    }

    &__clearIcon {
      border: none;
      background: transparent;
      padding: 0;
    }
  }

  &-showingResult {
    span {
      @include st2($oxfordBlue1);
    }
    &::before {
      content: '';
      color: $emerald;
      // border-right: 1px solid;
      // margin-right: 1rem;
    }
  }

  &-searchDropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;

    box-shadow: 0px 0px 0.416rem 0px rgba(20, 30, 56, 0.1803921569);
    background-color: $white;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 99;
    border-radius: 0.5rem;

    &__recentSearch {
      display: grid;
      grid-template-columns: 1rem 1fr max-content;
      align-items: center;
      grid-column-gap: 0.75rem;
      padding: 1rem 1rem 0;

      &--heading {
        @include h4($oxfordBlue1);
      }

      &--clearHandler {
        @include st2($emerald);
        cursor: pointer;
      }
    }

    &__list {
      list-style: none;
      padding-inline: 0;
      padding-block: 0;
      margin: 0;
      &--item {
        padding: 0.5rem 0.75rem;
        cursor: pointer;
        @include st2($oxfordBlue);
        &:hover {
          background-color: $emerald9;
        }
        &.item-active {
          background-color: $emerald9;
        }
      }
    }
  }
}
