@import '../../../assets/scss/colors.scss';

@import '../../../assets/scss/mixins/typography.scss';
@import '../../../assets/scss/mixins/button.scss';
@import '../../../assets/scss/mixins/labels.scss';
@import '../../../assets/scss/mixins/toast.scss';
@import '../../../assets/scss/mixins/tooltip.scss';
@import '../../../assets/scss/mixins/input.scss';
@import '../../../assets/scss/mixins/checkbox.scss';
@import '../../../assets/scss/mixins/dropdown.scss';
@import '../../../assets/scss/mixins/cards.scss';
@import '../../../assets/scss/mixins/table.scss';
@import '../../../assets/scss/mixins/utils.scss';

@mixin modals {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 27.5rem;
  padding: 2rem 3.5rem;
  text-align: center;
  background-color: $white;
}

@mixin placeHolderButtonWithImage {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
}

@mixin profileImagePlaceHolderRichContent {
  img,
  svg {
    width: 5.25rem;
    height: 5.25rem;
    border-radius: 100%;
    background-color: $emerald9;
    cursor: pointer;
  }
  button {
    display: none;
  }
  .mainImage {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 100%;
    background-color: $emerald8;
    cursor: pointer;
    position: relative;
    img,
    svg {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin defaultPlaceholderImage {
  .mainImage {
    width: 22.5rem;
    height: 15rem;
    img {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin richContentTestimonialCommon {
  @include small($oxfordBlue);
  display: grid;
}

@mixin round-card($shadow-color: $manatte8, $inner-card: true) {
  box-shadow: 0px 0px 4px 4px $shadow-color;
  border-radius: 1rem;
  padding: 1em;

  @if $inner-card {
    > * {
      height: 100%;
      border-radius: 0.875rem;
    }
  }
}

@mixin loading {
  background: $manatte;
  background: linear-gradient(
    305deg,
    $manatte,
    $manatte5,
    $manatte
  );
  background-size: 400% 400%;
  animation: gradient-animation 1s linear infinite;

  @keyframes gradient-animation {
    0% {
      background-position: 133% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

.FileUploadModal {
  width: 83.33vw;
  max-width: 75rem;
  height: 84vh;
  max-height: 47.3125rem;
  padding: 1.125rem 2.5rem;
  background: $white;
  box-shadow: 0 5px 10px 0 $oxfordBlue8;
  position: relative;
  overflow: scroll;
  border-radius: 0.625rem;

  &-title {
    @include h2($oxfordBlue1);
    margin-top: 1.5rem;
    margin-bottom: 2.5rem;
  }

  &-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.5rem;
    height: 78%;
    max-height: 35rem;

    &__left,
    &__right {
      width: calc(50% - 1.5rem);
    }

    // Left section
    &__left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &--uploadContainer {
        height: 65%;
        background-color: $emerald9;
        .uploadContainer {
          &-dropzone {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            user-select: none;

            &__icon {
              width: 2.625rem;
              margin-bottom: 0.625rem;
            }

            &__iconLarge {
              width: 4rem;
              margin-bottom: 0.625rem;
            }

            & > svg * {
              fill: $emerald;
            }

            &__textPrimary {
              @include st2($oxfordBlue1);
              margin-bottom: 0.3125rem;
              text-transform: capitalize;
            }

            &__textSecondary {
              @include small($oxfordBlue6);
            }

            &__textTertiary {
              @include st2($oxfordBlue6);
              margin: 0.625rem auto;
            }

            &__maxFilesMessage {
              @include st2($emerald);
              margin-top: 0.625rem;
            }
          }
        }
      }
      &--error {
        text-align: center;
        @include st2($sizzlingRed);
        min-height: 0.9375rem;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 0.5rem;
      }
    }

    // Right section
    &__right {
      height: 100%;
      &--files {
        height: 100%;
        overflow: scroll;
        padding-right: 1rem;
      }
      &--emptyText {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          @include h1($oxfordBlue8);
        }
      }
    }
  }
  &-bottom {
    &__line {
      width: 100%;
      height: 1px;
      background: $emerald7;
    }

    &__footer {
      margin-top: 1rem;
      button {
        margin: auto 0.3125rem;
      }
    }
  }

  &-urlUpload {
    padding: 1rem;
    background-color: $manatte8;
    text-align: center;
    height: auto;
    padding: 1.5rem;

    &__title {
      @include h2($oxfordBlue1);
    }
    &__desc {
      @include st2($oxfordBlue5);
      margin-top: 0.5rem;
    }
    &__input {
      .TextInput {
        width: 100%;
        margin-right: 1rem;

        &-labelContainer {
          display: none;
        }
      }
      margin-top: 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.FileProgressView {
  padding: 1.25rem 0;
  &-iconContainer {
    min-width: 2.5rem;
    min-height: 2.5rem;
    max-width: 2.5rem;
    max-height: 2.5rem;
    margin-right: 1rem;
    background-color: $emerald9;
  }
  &-fileContainer {
    width: 100%;
    &__name {
      margin-bottom: 0.5rem;
      @include st2($oxfordBlue1);
      .close-icon,
      .tick-icon {
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 50%;
        padding: 0.125rem;
      }
      .close-icon {
        background-color: $sizzlingRed;
        cursor: pointer;
      }
      .tick-icon {
        background-color: $emerald;
      }
    }
    &__progressBar {
      height: 0.5rem;
      width: 100%;
      background: $emerald9;
      position: relative;
      overflow: hidden;

      &--percentage {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background: $emerald;
      }
    }
  }
}
