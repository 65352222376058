@mixin dropdown-common {
  width: 17.625rem;
  max-height: 14.6875rem;
  overflow-y: auto;
  box-shadow: 0 6px 12px 0 $oxfordBlue8;
  background-color: $white;
}
@mixin dropdown {
  @include dropdown-common();
  margin: 0px;
  padding: 0.75rem 0;
  & > * {
    @include st2($oxfordBlue5);
    height: 2.25rem;
    padding: 0 1.25rem;
    border-bottom: 1px solid $oxfordBlue2;
    text-align: center;
    line-height: 2.25rem;
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      color: $oxfordBlue;
    }
  }
  .disabled {
    color: $oxfordBlue5;
    pointer-events: none;
  }
}
@mixin dropdown-with-checkbox {
  @include dropdown-common();
  margin: 0px;
  padding: 0.75rem 0;
  // display: grid;
  & > * {
    @include checkbox-with-label;
    height: 2.25rem;
    padding: 0 1.25rem;
    border-bottom: 1px solid $emerald8;
    &:last-child {
      border-bottom: none;
    }
  }
}
