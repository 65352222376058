@import '../../../../assets/scss/colors.scss';

@import '../../../../assets/scss/mixins/typography.scss';
@import '../../../../assets/scss/mixins/button.scss';
@import '../../../../assets/scss/mixins/labels.scss';
@import '../../../../assets/scss/mixins/toast.scss';
@import '../../../../assets/scss/mixins/tooltip.scss';
@import '../../../../assets/scss/mixins/input.scss';
@import '../../../../assets/scss/mixins/checkbox.scss';
@import '../../../../assets/scss/mixins/dropdown.scss';
@import '../../../../assets/scss/mixins/cards.scss';
@import '../../../../assets/scss/mixins/table.scss';
@import '../../../../assets/scss/mixins/utils.scss';

@mixin modals {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 27.5rem;
  padding: 2rem 3.5rem;
  text-align: center;
  background-color: $white;
}

@mixin placeHolderButtonWithImage {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
}

@mixin profileImagePlaceHolderRichContent {
  img,
  svg {
    width: 5.25rem;
    height: 5.25rem;
    border-radius: 100%;
    background-color: $emerald9;
    cursor: pointer;
  }
  button {
    display: none;
  }
  .mainImage {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 100%;
    background-color: $emerald8;
    cursor: pointer;
    position: relative;
    img,
    svg {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin defaultPlaceholderImage {
  .mainImage {
    width: 22.5rem;
    height: 15rem;
    img {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin richContentTestimonialCommon {
  @include small($oxfordBlue);
  display: grid;
}

@mixin round-card($shadow-color: $manatte8, $inner-card: true) {
  box-shadow: 0px 0px 4px 4px $shadow-color;
  border-radius: 1rem;
  padding: 1em;

  @if $inner-card {
    > * {
      height: 100%;
      border-radius: 0.875rem;
    }
  }
}

@mixin loading {
  background: $manatte;
  background: linear-gradient(
    305deg,
    $manatte,
    $manatte5,
    $manatte
  );
  background-size: 400% 400%;
  animation: gradient-animation 1s linear infinite;

  @keyframes gradient-animation {
    0% {
      background-position: 133% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

.filter-sidebar {
  width: 11.875rem;
  background: $white;
  height: 100%;
  min-height: 100%;
  overflow-y: auto;
  overflow-y: overlay;
  position: relative;
  box-shadow: 4px 0 8px $oxfordBlue9;
  z-index: 5;

  &__title {
    @include h2($oxfordBlue);
  }

  main {
    display: none;
  }

  &__top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.25rem;
  }

  &__clear-all {
    color: $oxfordBlue5;
    padding: 0 1.25rem;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @include st2();
    &:hover {
      color: $oxfordBlue;
    }
    transition: 0.2s ease;
  }

  &__toggler {
    background: $bleuDeFrance;
    color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    user-select: none;
    @include small();
    text-transform: capitalize;
    padding: 0.25rem 0.75rem;
    position: sticky;
    z-index: 1;
    top: 0;
    img {
      width: 1.25rem;
      margin-right: 0.5rem;
    }
    &.count {
      & span {
        font-weight: 500;
      }
      background-color: $emerald8;
    }
  }

  &--toggled {
    width: 1.5rem;
    *[aria-hidden] {
      display: none;
    }
    main {
      display: block;
    }
  }

  svg * {
    fill: $white;
  }

  &__links {
    margin-top: 3.25rem;
  }

  &__link {
    padding: 0.5rem 2.75rem 0.5rem 1.25rem;
    position: relative;
    display: block;
    @include st2($oxfordBlue);
    &:hover {
      color: $emerald5;
      &::after {
        color: $emerald5;
      }
    }
    &.active {
      color: $emerald;
      &::after {
        color: $emerald;
      }
    }
    &::after {
      position: absolute;
      content: '\203A';
      right: 1.5rem;
      top: 50%;
      transform: translateY(-50%);
      @include st2($oxfordBlue5);
    }

    &.separator {
      border-bottom: 0.5px solid $oxfordBlue9;
    }
  }
}

// Handling it as separate css
// As these are two separate nested elements
.filter-sidebar--toggled .filter-sidebar__toggler {
  justify-content: center;
  padding: 0.25rem 0.25rem;
}
