@import '../../../assets/scss/colors.scss';

@import '../../../assets/scss/mixins/typography.scss';
@import '../../../assets/scss/mixins/button.scss';
@import '../../../assets/scss/mixins/labels.scss';
@import '../../../assets/scss/mixins/toast.scss';
@import '../../../assets/scss/mixins/tooltip.scss';
@import '../../../assets/scss/mixins/input.scss';
@import '../../../assets/scss/mixins/checkbox.scss';
@import '../../../assets/scss/mixins/dropdown.scss';
@import '../../../assets/scss/mixins/cards.scss';
@import '../../../assets/scss/mixins/table.scss';
@import '../../../assets/scss/mixins/utils.scss';

@mixin modals {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 27.5rem;
  padding: 2rem 3.5rem;
  text-align: center;
  background-color: $white;
}

@mixin placeHolderButtonWithImage {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
}

@mixin profileImagePlaceHolderRichContent {
  img,
  svg {
    width: 5.25rem;
    height: 5.25rem;
    border-radius: 100%;
    background-color: $emerald9;
    cursor: pointer;
  }
  button {
    display: none;
  }
  .mainImage {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 100%;
    background-color: $emerald8;
    cursor: pointer;
    position: relative;
    img,
    svg {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin defaultPlaceholderImage {
  .mainImage {
    width: 22.5rem;
    height: 15rem;
    img {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin richContentTestimonialCommon {
  @include small($oxfordBlue);
  display: grid;
}

@mixin round-card($shadow-color: $manatte8, $inner-card: true) {
  box-shadow: 0px 0px 4px 4px $shadow-color;
  border-radius: 1rem;
  padding: 1em;

  @if $inner-card {
    > * {
      height: 100%;
      border-radius: 0.875rem;
    }
  }
}

@mixin loading {
  background: $manatte;
  background: linear-gradient(
    305deg,
    $manatte,
    $manatte5,
    $manatte
  );
  background-size: 400% 400%;
  animation: gradient-animation 1s linear infinite;

  @keyframes gradient-animation {
    0% {
      background-position: 133% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

.filter-item {
  border-top: 0.03125rem solid $oxfordBlue9;

  user-select: none;
  letter-spacing: -0.02875rem;

  &[open] &__summary {
    color: $oxfordBlue1;
    background: $emerald9;
    &::after {
      content: '-';
      padding: 0px 3px;
      color: $oxfordBlue1;
    }
  }

  &__summary {
    padding: 0.75rem;
    list-style: none !important;
    position: relative;
    @include small();
    letter-spacing: -0.02875rem;
    color: $oxfordBlue5;
    text-transform: uppercase;
    user-select: none;
    outline: none;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;

    &::-webkit-details-marker {
      display: none;
    }

    &::after {
      content: '+';
      font-size: 1.7rem;
      position: absolute;

      right: 5%;

      font-weight: lighter;
      color: $oxfordBlue8;
    }
  }

  &__label {
    padding: 0.4375rem 1.25rem;
    @include st2($oxfordBlue5);
    font-weight: 300;
    display: grid;
    justify-content: start;
    align-items: center;
    grid-template-columns: auto auto;
    grid-column-gap: 0.625rem;
    margin-bottom: 0;
    font-weight: normal;
    span {
      display: inline-flex;
      width: 8rem;
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
      @include st2();
      align-items: center;
      justify-content: flex-start;
    }

    &--disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  &__badge {
    display: inline-block;
    width: 1.2rem !important;
    height: 0.8125rem;
    border-radius: 0.4375rem;
    margin-right: 0.2rem;

    &--not-optimized {
      background: $sizzlingRed;
    }

    &--partly-optimized {
      background: $brightYellowCrayola;
    }

    &--fully-optimized {
      background: $emerald;
    }
  }

  &__show-more {
    letter-spacing: -0.43px;
    padding: 0.4375rem 1.25rem 0.6875rem 1.25rem;
    @include st2();
    text-decoration: underline;
    user-select: none;
    cursor: pointer;
    &::first-letter {
      text-transform: uppercase;
    }
    font-weight: normal;
    color: $capri1;
  }

  &__colors-row {
    margin-top: 0.625rem;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(3, 2.75rem);
    grid-gap: 0.5rem;
    padding: 0 0.9375rem;
  }

  &__color {
    padding: 0.1875rem !important;
    height: 2.75rem;
    border: 0.0625rem solid $oxfordBlue9;
    display: block !important;

    &:hover {
      border: 0.0625rem solid $oxfordBlue1 !important;
    }
    div {
      width: 100%;
      height: 100%;
      flex: 1;
      cursor: pointer;
    }

    input,
    input::before {
      display: none;
      width: 0 !important;
      height: 0 !important;
    }

    &--active {
      &:hover {
        border: 0.125rem solid $emerald !important;
      }
      border: 0.125rem solid $emerald;
    }
  }
}

// Handling color disabled state as a separate entity
// Rather then nesting it
.filter-item__color.filter-item__label--disabled {
  &:hover {
    border: 0.0625rem solid $oxfordBlue9 !important;
  }
  & div {
    cursor: not-allowed;
  }
}
