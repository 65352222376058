@mixin button_hover($background, $border, $color) {
  background: $background;
  border: 1px solid $border;
  color: $color;
}

@mixin common($size, $variant, $color) {
  @if $size == 'large' {
    @include sh3($color);
    min-width: 20rem;
    border-radius: 1.5rem;
    height: var(--large-button-height);
    padding: 0 2rem;
  }
  // Default State
  @if $size == 'medium' {
    @include sh4($color);
    min-width: 6.5rem;
    border-radius: 1rem;
    height: var(--medium-button-height);
    padding: 0 2rem;
  }
  @if $size == 'small' {
    @include button2($color);
    min-width: 3.875rem;
    border-radius: 0.625rem;
    height: var(--small-button-height);
    padding: 0 1rem;
  }
  @if $size == 'medium' or $size == 'small' or $size == 'large' {
    background: $white;
    @if $variant == 'gradient' {
      border: none;
      background: var(--primary-gradient);
    }
    @if $variant == 'gray' {
      border: 1px solid $oxfordBlue;
      color: $oxfordBlue;
    }
    @if $variant == 'green' {
      border: 1px solid $emerald;
      color: $emerald;
    }
    @if $variant == 'red' {
      border: 1px solid $sizzlingRed;
      color: $sizzlingRed;
    }
    @if $variant == 'white' {
      border: 1px solid $white;
      color: $oxfordBlue;
    }
  }
  // Focused State
  &:active {
    color: $white;
    border: none;
    @if $variant == 'gradient' {
      background: $capri;
    }
    @if $variant == 'gray' {
      background: $oxfordBlue;
    }
    @if $variant == 'green' {
      background: $emerald;
    }
    @if $variant == 'red' {
      background: $sizzlingRed;
    }
    @if $variant == 'white' {
      background: $white;
    }
  }
  // Hover State
  &:hover {
    @if $variant == 'gradient' {
      @include button_hover($white, $capri, $capri);
    }
    @if $variant == 'gray' {
      @include button_hover(
        $manatte9,
        $oxfordBlue,
        $oxfordBlue
      );
    }
    @if $variant == 'green' {
      @include button_hover(
        $emerald9,
        $emerald,
        $emerald
      );
    }
    @if $variant == 'red' {
      @include button_hover(
        $sizzlingRed9,
        $sizzlingRed,
        $sizzlingRed
      );
    }
    @if $variant == 'white' {
      @include button_hover(
        $oxfordBlue9,
        $oxfordBlue9,
        $oxfordBlue
      );
    }
  }
}

@mixin btn-primary($size: medium, $variant: 'gradient', $color: $white) {
  border-radius: 0.625rem;
  min-width: 3.875rem;
  height: var(--small-button-height);
  @include common($size, $variant, $color);
  outline: none;
}

@mixin btn-secondary(
  $size: small,
  $border: $emerald,
  $color: $emerald
) {
  border: solid 1px $border;
  min-width: 3.875rem;
  border-radius: 0.5rem;
  background-color: $white;
  @include common($size, $border, $color);
  outline: none;
}

// suffix "new" will be removed once all above btn-secondary is removed
@mixin btn-secondary-new {
  border: none;
  border-radius: 1.5rem;
  padding: 0 1rem;
  min-width: 3.875rem;
  height: var(--secondary-button-height);
  background-color: $emerald;
  @include button2($white);
  outline: none;
  // Focused State
  &:active {
    color: $white;
    background: $emerald;
  }
  // Hover State
  &:hover {
    box-shadow: 0px 4px 8px $oxfordBlue8;
  }
}

@mixin btn-with-label-icon-hor {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 0.75rem;
  align-items: center;
  svg {
    height: 1.625rem;
    width: 1.625rem;
  }
  p {
    @include sh3($oxfordBlue5);
    margin: 0px;
  }
}
@mixin btn-with-out-label-only-icon {
  display: grid;
  width: 1.5rem;
  height: 1.5rem;
  background-color: $emerald;
  border-radius: 50%;
  border: none;
  svg {
    align-self: center;
    position: relative;
    right: 0.37rem;
  }
  &:hover {
    box-shadow: 0px 4px 8px $oxfordBlue8;
  }
}
