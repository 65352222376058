@mixin toast(
  $color: $oxfordBlue1,
  $background: $emerald9,
  $border: $emerald
) {
  text-transform: uppercase;
  padding: 0 2rem;
  position: fixed;
  min-width: 14.625rem;
  height: 1.75rem;
  border: 1px solid $border;
  background-color: $background;
  @include st2($color);
  display: grid;
  place-items: center;
  box-shadow: 0 2px 4px 0 $oxfordBlue6;
  z-index: 9999;
  p {
    @include st2($color);
  }
  &.danger {
    border: 1px solid $sizzlingRed;
    background: $sizzlingRed9;
  }
}
