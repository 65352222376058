@import '../../../../../assets/scss/colors.scss';

@import '../../../../../assets/scss/mixins/typography.scss';
@import '../../../../../assets/scss/mixins/button.scss';
@import '../../../../../assets/scss/mixins/labels.scss';
@import '../../../../../assets/scss/mixins/toast.scss';
@import '../../../../../assets/scss/mixins/tooltip.scss';
@import '../../../../../assets/scss/mixins/input.scss';
@import '../../../../../assets/scss/mixins/checkbox.scss';
@import '../../../../../assets/scss/mixins/dropdown.scss';
@import '../../../../../assets/scss/mixins/cards.scss';
@import '../../../../../assets/scss/mixins/table.scss';
@import '../../../../../assets/scss/mixins/utils.scss';

@mixin modals {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 27.5rem;
  padding: 2rem 3.5rem;
  text-align: center;
  background-color: $white;
}

@mixin placeHolderButtonWithImage {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
}

@mixin profileImagePlaceHolderRichContent {
  img,
  svg {
    width: 5.25rem;
    height: 5.25rem;
    border-radius: 100%;
    background-color: $emerald9;
    cursor: pointer;
  }
  button {
    display: none;
  }
  .mainImage {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 100%;
    background-color: $emerald8;
    cursor: pointer;
    position: relative;
    img,
    svg {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin defaultPlaceholderImage {
  .mainImage {
    width: 22.5rem;
    height: 15rem;
    img {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin richContentTestimonialCommon {
  @include small($oxfordBlue);
  display: grid;
}

@mixin round-card($shadow-color: $manatte8, $inner-card: true) {
  box-shadow: 0px 0px 4px 4px $shadow-color;
  border-radius: 1rem;
  padding: 1em;

  @if $inner-card {
    > * {
      height: 100%;
      border-radius: 0.875rem;
    }
  }
}

@mixin loading {
  background: $manatte;
  background: linear-gradient(
    305deg,
    $manatte,
    $manatte5,
    $manatte
  );
  background-size: 400% 400%;
  animation: gradient-animation 1s linear infinite;

  @keyframes gradient-animation {
    0% {
      background-position: 133% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

.experience-card {
  width: 400px;
  height: 250px;
  cursor: pointer;
  background-color: white;
  @include round-card();

  &.active {
    @include round-card(var(--experience-card-secondary-color));
  }

  &-loading {
    @include loading;
  }

  &-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    position: relative;
    padding: 1em;

    background-color: var(--experience-card-background-color);
    border: 2px solid var(--experience-card-secondary-color);

    &.active {
      border-color: var(--experience-card-primary-color);
    }

    input[type='radio'].select {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      cursor: pointer;
      accent-color: var(--experience-card-primary-color);
    }

    svg {
      width: 75%;
    }

    h4 {
      font-weight: 700;
      font-size: 1rem;
    }
  }

  &-hover {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: var(--experience-card-background-color);
    border-radius: 0.875rem;
    font-size: 0.875rem;
    padding: 0.75em;

    &:hover {
      opacity: 1;
    }
  }
}
