@import '../../../assets/scss/colors.scss';

@import '../../../assets/scss/mixins/typography.scss';
@import '../../../assets/scss/mixins/button.scss';
@import '../../../assets/scss/mixins/labels.scss';
@import '../../../assets/scss/mixins/toast.scss';
@import '../../../assets/scss/mixins/tooltip.scss';
@import '../../../assets/scss/mixins/input.scss';
@import '../../../assets/scss/mixins/checkbox.scss';
@import '../../../assets/scss/mixins/dropdown.scss';
@import '../../../assets/scss/mixins/cards.scss';
@import '../../../assets/scss/mixins/table.scss';
@import '../../../assets/scss/mixins/utils.scss';

@mixin modals {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 27.5rem;
  padding: 2rem 3.5rem;
  text-align: center;
  background-color: $white;
}

@mixin placeHolderButtonWithImage {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
}

@mixin profileImagePlaceHolderRichContent {
  img,
  svg {
    width: 5.25rem;
    height: 5.25rem;
    border-radius: 100%;
    background-color: $emerald9;
    cursor: pointer;
  }
  button {
    display: none;
  }
  .mainImage {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 100%;
    background-color: $emerald8;
    cursor: pointer;
    position: relative;
    img,
    svg {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin defaultPlaceholderImage {
  .mainImage {
    width: 22.5rem;
    height: 15rem;
    img {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin richContentTestimonialCommon {
  @include small($oxfordBlue);
  display: grid;
}

@mixin round-card($shadow-color: $manatte8, $inner-card: true) {
  box-shadow: 0px 0px 4px 4px $shadow-color;
  border-radius: 1rem;
  padding: 1em;

  @if $inner-card {
    > * {
      height: 100%;
      border-radius: 0.875rem;
    }
  }
}

@mixin loading {
  background: $manatte;
  background: linear-gradient(
    305deg,
    $manatte,
    $manatte5,
    $manatte
  );
  background-size: 400% 400%;
  animation: gradient-animation 1s linear infinite;

  @keyframes gradient-animation {
    0% {
      background-position: 133% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

%modal-elements-layout {
  background: $white;
  position: relative;
  padding: 1.5rem;
  color: $oxfordBlue;
  z-index: 1;
}

%modal-layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@mixin modal-elements {
  &__header {
    @extend %modal-elements-layout;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
    border-radius: 0.625rem 0.625rem 0 0;
  }

  &__body {
    overflow: auto;
    overflow: overlay;
    background: $manatte9;
    height: inherit;
  }

  &__footer {
    @extend %modal-elements-layout;
    box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.16);
    border-radius: 0 0 0.625rem 0.625rem;
  }
}

.semiFullScreenModal {
  width: calc(100% - 4rem);
  max-width: 75rem;
  height: 80vh;
  @extend %modal-layout;
  @include modal-elements;
  border-radius: 0.625rem;
}

.fullScreenModal {
  width: 100vw;
  height: 100vh;
  @extend %modal-layout;
  @include modal-elements;

  .modal__close-button {
    right: 2rem;
    top: 1.375rem;
    img {
      height: 1.375rem;
      width: 1.375rem;
    }
  }
}
