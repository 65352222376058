@mixin height-width-round-border($height: 100%, $witdh: 100%) {
  height: $height;
  width: $witdh;
  border-radius: 50%;
}

@mixin absolutePosition($top: 0, $left: 0, $right: 0, $bottom: 0) {
  position: absolute;
  top: $top;
  left: $left;
  right: $right;
  bottom: $bottom;
}
