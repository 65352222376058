@mixin tooltip(
  $color: $oxfordBlue1,
  $background: $manatte9
) {
  background-color: $background;
  box-shadow: 0px 2px 4px $oxfordBlue9;
  margin-top: 0.1875rem !important; // To overwrite default default margin-top of tooltip
  padding: 0.4rem 0.1rem;
  min-width: 5.75rem;
  border-radius: 0.375rem;
  text-align: center;
  @include small($color);
  &::before {
    content: none;
  }
  &::after {
    content: none;
  }
}

@mixin secondary-tooltip {
  @include sh2($white);
  background-color: $oxfordBlue;
  border-radius: 8px;
}
