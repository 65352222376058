@import '../../assets/scss/colors.scss';

@import '../../assets/scss/mixins/typography.scss';
@import '../../assets/scss/mixins/button.scss';
@import '../../assets/scss/mixins/labels.scss';
@import '../../assets/scss/mixins/toast.scss';
@import '../../assets/scss/mixins/tooltip.scss';
@import '../../assets/scss/mixins/input.scss';
@import '../../assets/scss/mixins/checkbox.scss';
@import '../../assets/scss/mixins/dropdown.scss';
@import '../../assets/scss/mixins/cards.scss';
@import '../../assets/scss/mixins/table.scss';
@import '../../assets/scss/mixins/utils.scss';

@mixin modals {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 27.5rem;
  padding: 2rem 3.5rem;
  text-align: center;
  background-color: $white;
}

@mixin placeHolderButtonWithImage {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
}

@mixin profileImagePlaceHolderRichContent {
  img,
  svg {
    width: 5.25rem;
    height: 5.25rem;
    border-radius: 100%;
    background-color: $emerald9;
    cursor: pointer;
  }
  button {
    display: none;
  }
  .mainImage {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 100%;
    background-color: $emerald8;
    cursor: pointer;
    position: relative;
    img,
    svg {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin defaultPlaceholderImage {
  .mainImage {
    width: 22.5rem;
    height: 15rem;
    img {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin richContentTestimonialCommon {
  @include small($oxfordBlue);
  display: grid;
}

@mixin round-card($shadow-color: $manatte8, $inner-card: true) {
  box-shadow: 0px 0px 4px 4px $shadow-color;
  border-radius: 1rem;
  padding: 1em;

  @if $inner-card {
    > * {
      height: 100%;
      border-radius: 0.875rem;
    }
  }
}

@mixin loading {
  background: $manatte;
  background: linear-gradient(
    305deg,
    $manatte,
    $manatte5,
    $manatte
  );
  background-size: 400% 400%;
  animation: gradient-animation 1s linear infinite;

  @keyframes gradient-animation {
    0% {
      background-position: 133% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

.ListingCard {
  padding: 0.625rem;
  position: relative;
  &.no-pointer {
    &:hover {
      cursor: initial;
    }
  }

  &-label {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 12.5rem;
    margin-bottom: 0.25rem;
    position: relative;
    background: $manatte9;

    &.createCard {
      background-color: $emerald9;
    }

    &:hover > .round-checkbox {
      opacity: 1;
    }
    .round-checkbox {
      position: absolute;
      z-index: 1;
      top: 0.3125rem;
      right: 0.3125rem;
    }

    .VideoOverlay {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }

  &-image,
  &-video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &-svg,
  &-document {
    width: 6.25rem;
    height: 6.25rem;
    object-fit: contain;
  }

  &-assetType {
    @include small($oxfordBlue5);
    text-transform: uppercase;
  }

  &-title {
    @include h2($oxfordBlue1);
    margin: 0.8125rem auto 0.6875rem auto;
    display: -webkit-box;
    line-clamp: 2;
    display: -moz-box;
    box-orient: vertical;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 2.375rem;
    &:hover {
      color: $oxfordBlue;
    }
  }

  &-createImage {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $emerald9;
  }

  &-createNewButton {
    display: block;
    width: fit-content;
    margin: 1.8125rem auto 1.8125rem auto;
    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__status {
    position: absolute;
    z-index: 1;

    &--position_right {
      right: 0.75rem;
    }
  }

  &-disabledLayer {
    @include absolutePosition();
    z-index: 1;
    background: rgba(20, 30, 56, 0.7);
    display: grid;
    h3 {
      @include h2($white);
      margin: auto;
    }
  }

  &-bottomLabel {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    background-color: $emerald;
    @include h5($white);
    z-index: 2;
    padding: 0.2rem 0;
    text-transform: uppercase;
  }
}
