@import '../../assets/scss/colors.scss';

@import '../../assets/scss/mixins/typography.scss';
@import '../../assets/scss/mixins/button.scss';
@import '../../assets/scss/mixins/labels.scss';
@import '../../assets/scss/mixins/toast.scss';
@import '../../assets/scss/mixins/tooltip.scss';
@import '../../assets/scss/mixins/input.scss';
@import '../../assets/scss/mixins/checkbox.scss';
@import '../../assets/scss/mixins/dropdown.scss';
@import '../../assets/scss/mixins/cards.scss';
@import '../../assets/scss/mixins/table.scss';
@import '../../assets/scss/mixins/utils.scss';

@mixin modals {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 27.5rem;
  padding: 2rem 3.5rem;
  text-align: center;
  background-color: $white;
}

@mixin placeHolderButtonWithImage {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
}

@mixin profileImagePlaceHolderRichContent {
  img,
  svg {
    width: 5.25rem;
    height: 5.25rem;
    border-radius: 100%;
    background-color: $emerald9;
    cursor: pointer;
  }
  button {
    display: none;
  }
  .mainImage {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 100%;
    background-color: $emerald8;
    cursor: pointer;
    position: relative;
    img,
    svg {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin defaultPlaceholderImage {
  .mainImage {
    width: 22.5rem;
    height: 15rem;
    img {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin richContentTestimonialCommon {
  @include small($oxfordBlue);
  display: grid;
}

@mixin round-card($shadow-color: $manatte8, $inner-card: true) {
  box-shadow: 0px 0px 4px 4px $shadow-color;
  border-radius: 1rem;
  padding: 1em;

  @if $inner-card {
    > * {
      height: 100%;
      border-radius: 0.875rem;
    }
  }
}

@mixin loading {
  background: $manatte;
  background: linear-gradient(
    305deg,
    $manatte,
    $manatte5,
    $manatte
  );
  background-size: 400% 400%;
  animation: gradient-animation 1s linear infinite;

  @keyframes gradient-animation {
    0% {
      background-position: 133% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

.ActionOverlay {
  position: relative;

  &__container {
    opacity: 0;
    height: 100%;
    transition: 0.5s ease;
    background-color: $oxfordBlue;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.active {
      visibility: hidden;
    }

    &.inactive {
      visibility: visible;
    }

    &:hover {
      opacity: 0.8;
    }

    &___actions {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: repeat(5, 1fr);
      direction: rtl;
      user-select: none;
      height: 100%;
      width: 100%;

      &__action {
        align-self: center;
        text-align: center;
        position: relative;
        &.top {
          grid-row-end: 2;
        }
        &.center {
          grid-column: 3;
          grid-row: 3 / 4;
        }
        &.bottom {
          grid-row-start: 5;
        }

        &[title]:hover::after {
          content: attr(title);
          position: absolute;
          bottom: -1.25rem;
          right: -0.625rem;
          @include tooltip();
        }
      }
    }
  }
}
