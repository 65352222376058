@import '../../../assets/scss/colors.scss';

@import '../../../assets/scss/mixins/typography.scss';
@import '../../../assets/scss/mixins/button.scss';
@import '../../../assets/scss/mixins/labels.scss';
@import '../../../assets/scss/mixins/toast.scss';
@import '../../../assets/scss/mixins/tooltip.scss';
@import '../../../assets/scss/mixins/input.scss';
@import '../../../assets/scss/mixins/checkbox.scss';
@import '../../../assets/scss/mixins/dropdown.scss';
@import '../../../assets/scss/mixins/cards.scss';
@import '../../../assets/scss/mixins/table.scss';
@import '../../../assets/scss/mixins/utils.scss';

@mixin modals {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 27.5rem;
  padding: 2rem 3.5rem;
  text-align: center;
  background-color: $white;
}

@mixin placeHolderButtonWithImage {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
}

@mixin profileImagePlaceHolderRichContent {
  img,
  svg {
    width: 5.25rem;
    height: 5.25rem;
    border-radius: 100%;
    background-color: $emerald9;
    cursor: pointer;
  }
  button {
    display: none;
  }
  .mainImage {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 100%;
    background-color: $emerald8;
    cursor: pointer;
    position: relative;
    img,
    svg {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin defaultPlaceholderImage {
  .mainImage {
    width: 22.5rem;
    height: 15rem;
    img {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin richContentTestimonialCommon {
  @include small($oxfordBlue);
  display: grid;
}

@mixin round-card($shadow-color: $manatte8, $inner-card: true) {
  box-shadow: 0px 0px 4px 4px $shadow-color;
  border-radius: 1rem;
  padding: 1em;

  @if $inner-card {
    > * {
      height: 100%;
      border-radius: 0.875rem;
    }
  }
}

@mixin loading {
  background: $manatte;
  background: linear-gradient(
    305deg,
    $manatte,
    $manatte5,
    $manatte
  );
  background-size: 400% 400%;
  animation: gradient-animation 1s linear infinite;

  @keyframes gradient-animation {
    0% {
      background-position: 133% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

:root {
  --asset-gallery-border-radius: 0.5rem;
}

.attribute-field {
  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  .top-row {
    display: flex;

    h5 {
      font-size: 0.875rem;
      margin-bottom: 0.5em;
    }

    .limits {
      font-size: 0.675rem;
      font-weight: 300;
      margin-left: 0.5rem;

      &.invalid {
        color: $sizzlingRed;
      }
    }

    .content-reference-button {
      margin-left: auto;
    }
  }

  input[type='text'],
  .RichTextEditor {
    background: none;
    padding-inline: 0.5rem;
    border: 1px solid $oxfordBlue;
    border-radius: 0.375em;
    transition: border-color 0.3s ease;
  }

  input[type='text'] {
    display: block;
    width: 100%;
    padding: 0.5rem 0.8rem;
    border: 1px solid $oxfordBlue;
  }

  input[type='text']:hover,
  input[type='text']:focus {
    outline: none;
    border: 2px solid $emerald !important;
  }

  input[type='text'].invalidInput {
    border: 2px solid $sizzlingRed !important;
  }

  input[type='text'].invalidInput:hover,
  input[type='text'].invalidInput:focus {
    border: 2px solid $sizzlingRed !important;
  }
  .RichTextEditor textarea {
    border: 0;
  }

  .gallery,
  .rich-content-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 150px);
    grid-auto-rows: 150px;
    gap: 0.5em;

    .ActionOverlay.rich-content-item {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $emerald9;
      border-radius: var(--asset-gallery-border-radius);

      > svg {
        width: 50px;
        height: 50px;

        path {
          fill: $emerald;
        }
      }

      .ActionOverlay__container {
        border-radius: var(--asset-gallery-border-radius);
      }
    }

    .add-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $emerald9;
      border-radius: var(--asset-gallery-border-radius);

      svg {
        width: 3.75rem;
        height: 3.75rem;
      }
    }
  }

  .gallery {
    > :first-child {
      grid-column-end: span 2;
      grid-row-end: span 2;
    }

    .ActionOverlay.asset-gallery-item {
      width: 100%;
      height: 100%;

      > * {
        border-radius: var(--asset-gallery-border-radius);
      }

      > :first-child {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}

body > .ActionOverlay.asset-gallery-item > :first-child {
  width: 100%;
  height: 100%;
  border-radius: var(--asset-gallery-border-radius);
  object-fit: cover;
}

body > .ActionOverlay.rich-content-item {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $emerald9;
  border-radius: var(--asset-gallery-border-radius);
  :first-child {
    width: 50px;
    height: 50px;

    path {
      fill: $emerald;
    }
  }
}

.rich-content-modal {
  &__header {
    text-align: center;
    font-weight: 700;
  }
  &__instruction {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  &__options {
    display: grid;
    grid-template-columns: 8rem 8rem 8rem;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;

    > * {
      cursor: pointer;
      text-align: center;

      svg {
        background: $emerald9;
        padding: 0.5em;
        border-radius: 0.5em;
        margin-bottom: 0.25rem;

        width: 3.5rem;
        height: 3.5rem;

        path {
          fill: $emerald;
        }
      }
      p {
        font-size: 0.875rem;
      }
    }
  }
}

.html-rich-content-editor-modal {
  min-width: MIN(MAX(MIN(400px, 90vw), 50vw), 800px);

  .ConfirmationModal {
    width: 100%;

    textarea {
      min-width: MIN(MAX(MIN(400px, 90vw), 50vw), 800px);
      min-height: MIN(MAX(MIN(400px, 50vh), 30vh), 800px);
    }
  }
}
.url-rich-content-editor-modal {
  .TextareaWithoutBorder {
    width: 100%;
  }
}
