@import '../../assets/scss/colors.scss';

@import '../../assets/scss/mixins/typography.scss';
@import '../../assets/scss/mixins/button.scss';
@import '../../assets/scss/mixins/labels.scss';
@import '../../assets/scss/mixins/toast.scss';
@import '../../assets/scss/mixins/tooltip.scss';
@import '../../assets/scss/mixins/input.scss';
@import '../../assets/scss/mixins/checkbox.scss';
@import '../../assets/scss/mixins/dropdown.scss';
@import '../../assets/scss/mixins/cards.scss';
@import '../../assets/scss/mixins/table.scss';
@import '../../assets/scss/mixins/utils.scss';

@mixin modals {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 27.5rem;
  padding: 2rem 3.5rem;
  text-align: center;
  background-color: $white;
}

@mixin placeHolderButtonWithImage {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
}

@mixin profileImagePlaceHolderRichContent {
  img,
  svg {
    width: 5.25rem;
    height: 5.25rem;
    border-radius: 100%;
    background-color: $emerald9;
    cursor: pointer;
  }
  button {
    display: none;
  }
  .mainImage {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 100%;
    background-color: $emerald8;
    cursor: pointer;
    position: relative;
    img,
    svg {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin defaultPlaceholderImage {
  .mainImage {
    width: 22.5rem;
    height: 15rem;
    img {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin richContentTestimonialCommon {
  @include small($oxfordBlue);
  display: grid;
}

@mixin round-card($shadow-color: $manatte8, $inner-card: true) {
  box-shadow: 0px 0px 4px 4px $shadow-color;
  border-radius: 1rem;
  padding: 1em;

  @if $inner-card {
    > * {
      height: 100%;
      border-radius: 0.875rem;
    }
  }
}

@mixin loading {
  background: $manatte;
  background: linear-gradient(
    305deg,
    $manatte,
    $manatte5,
    $manatte
  );
  background-size: 400% 400%;
  animation: gradient-animation 1s linear infinite;

  @keyframes gradient-animation {
    0% {
      background-position: 133% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

.RangeDatePickerNoBorder {
  position: relative;
  &-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.3125rem 0.75rem;
    border: 0 !important;
    &:hover,
    &:focus {
      border: 0;
    }
  }

  &-picker {
    position: absolute;
    background-color: $white;
    padding: 0 1rem;
    box-shadow: 0px 6px 18px $oxfordBlue8;
    z-index: 10;
    right: 0;
    border-radius: 0.625rem;

    &-main {
      display: flex;
      border-bottom: 1px solid $oxfordBlue9;
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      padding: 0.5rem 0;

      &__closeButton {
        @include small($oxfordBlue5);
        font-weight: unset;
        min-width: 3rem;
        padding: 0;
      }
    }

    &--predefined {
      padding: 3.75rem 1.25rem;
      border-right: 1px solid $oxfordBlue9;
    }

    &--range {
      padding: 3.5rem 1.875rem 2.5rem 2.5rem;
      display: grid;
      grid-template-columns: repeat(2, max-content);
    }
  }
}

.react-datepicker {
  border: 0;

  &__header {
    background-color: white !important;
    border: 0 !important;
  }

  &__navigation-icon::before {
    width: 8px !important;
    height: 8px !important;
  }

  &__current-month {
    font-size: 12px !important;
    font-weight: 500 !important;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  &__navigation-icon::before {
    top: 36% !important;
    border-width: 2px 2px 0 0 !important;
    border-color: $oxfordBlue5 !important;
  }

  &__navigation-icon--previous::before {
    right: 0 !important;
  }

  &__navigation-icon--next::before {
    left: 0 !important;
  }

  &__day-names &__day-name {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    color: #898e9b !important;
    /* margin: 0 8px 8px 0; */
  }

  &__day-name,
  &__day,
  &__time-name {
    padding: 4px !important;
    margin: 6px 0 !important;
    width: 32px !important;
    font-weight: 500 !important;
    font-size: 12px;
    line-height: 15px !important;
  }

  &__day:hover,
  &__month-text:hover,
  &__quarter-text:hover,
  &__year-text:hover {
    border-radius: 0 !important;
    background-color: #e6fcf2 !important;
  }

  &__day--selected,
  &__day--in-selecting-range,
  &__day--in-range,
  &__day--keyboard-selected {
    background: #e6fcf2 !important;
    border-radius: 0 !important;
    border: 0 !important;
    color: #2b344b !important;
    outline: 0;
  }

  &__day--outside-month {
    color: transparent !important;
    background: transparent !important;
    pointer-events: none;
  }

  &__day--selected {
    color: $emerald !important;
    border: 1px solid $emerald !important;
  }
  &__day--range-end {
    color: $emerald !important;
    border: 1px solid $emerald !important;
  }
  &__day--disabled:hover {
    background-color: $oxfordBlue9 !important;
  }
  &__day--outside-month {
    border: none !important;
  }
}
