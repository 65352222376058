@import '../../assets/scss/colors.scss';

@import '../../assets/scss/mixins/typography.scss';
@import '../../assets/scss/mixins/button.scss';
@import '../../assets/scss/mixins/labels.scss';
@import '../../assets/scss/mixins/toast.scss';
@import '../../assets/scss/mixins/tooltip.scss';
@import '../../assets/scss/mixins/input.scss';
@import '../../assets/scss/mixins/checkbox.scss';
@import '../../assets/scss/mixins/dropdown.scss';
@import '../../assets/scss/mixins/cards.scss';
@import '../../assets/scss/mixins/table.scss';
@import '../../assets/scss/mixins/utils.scss';

@mixin modals {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 27.5rem;
  padding: 2rem 3.5rem;
  text-align: center;
  background-color: $white;
}

@mixin placeHolderButtonWithImage {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
}

@mixin profileImagePlaceHolderRichContent {
  img,
  svg {
    width: 5.25rem;
    height: 5.25rem;
    border-radius: 100%;
    background-color: $emerald9;
    cursor: pointer;
  }
  button {
    display: none;
  }
  .mainImage {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 100%;
    background-color: $emerald8;
    cursor: pointer;
    position: relative;
    img,
    svg {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin defaultPlaceholderImage {
  .mainImage {
    width: 22.5rem;
    height: 15rem;
    img {
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
    button {
      @include placeHolderButtonWithImage;
    }
  }
}

@mixin richContentTestimonialCommon {
  @include small($oxfordBlue);
  display: grid;
}

@mixin round-card($shadow-color: $manatte8, $inner-card: true) {
  box-shadow: 0px 0px 4px 4px $shadow-color;
  border-radius: 1rem;
  padding: 1em;

  @if $inner-card {
    > * {
      height: 100%;
      border-radius: 0.875rem;
    }
  }
}

@mixin loading {
  background: $manatte;
  background: linear-gradient(
    305deg,
    $manatte,
    $manatte5,
    $manatte
  );
  background-size: 400% 400%;
  animation: gradient-animation 1s linear infinite;

  @keyframes gradient-animation {
    0% {
      background-position: 133% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

.TextareaWithoutBorder {
  &-labelContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    @include small($oxfordBlue);
    &__label {
      margin-bottom: 0;
      img,
      svg {
        width: 1rem;
        height: 1rem;
        margin-right: 0.3125rem;
      }
    }
    &__textRemaining {
      @include small($oxfordBlue1);
      &.limit-exceeded {
        color: $sizzlingRed;
      }
    }
  }
  &-textarea {
    resize: none;
    border: 1px solid $oxfordBlue;
    border-radius: 8px;
    width: 100%;
    padding: 0.25rem;
    @include st2($oxfordBlue1);
    background-color: $white;
    &:focus {
      outline: none;
    }
    &:hover,
    &:focus {
      border: 2px solid $emerald;
    }
    &.secondary {
      height: 7.5rem;
      padding: 0.75rem;
    }
    &.inactive {
      opacity: 0.4;
    }
  }

  &.textarea-error {
    .TextareaWithoutBorder-textarea {
      border: 1px solid $sizzlingRed;
    }
    .TextareaWithoutBorder-labelContainer {
      color: $sizzlingRed;
    }
  }
}
